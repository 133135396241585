// Functions for Walt Disney World searches; v2.0.0
// Author: Tom Nelson, December 2015

// -------------------------------------------------------------------------------------------------------------------

// Quick quote panel; homepage only

// Determine if user is a travel agent
var agent = ($('body').attr('data-agent') == 'true');

// Check if selected dates are eligible for current offer
function offerEligible(outbound, inbound) {
    if (typeof showPrelaunchSearchWarningModal !== 'undefined' && showPrelaunchSearchWarningModal) {
        warningOfferPrelaunchModal();
        return false;
    }

    var offerType = $('#quick-quote').attr('data-offer-type');
    if (offerType == null || offerType == 'undefined') { offerType = 'free-dine' };
    // Is the arrival journey after the last offer date? Or is the journey window is excluded from the offer
    /*if (inbound >= departureWindowEnd.getTime() && offerType != 'free-nights') {
        warning('Sorry, your return journey is after ' + moment(departureWindowEnd).subtract(1, 'days').format('Do MMMM YYYY') + '.', 'Unfortunately Disney Hotels are not yet on sale for the dates you\'ve chosen. Please adjust your dates or call our Disney Experts freephone on ' + phone + '* for help with your enquiry.')
        return false;
    }*/
    //Free Dine
    // Is the arrival journey before the offer start date?
    /*if (outbound.getTime() < arrivalWindowStart.getTime() && offerType != 'free-nights') {
        warning('Your dates aren\'t eligible for our Early Booker Package offer.', 'Unfortunately, the dates you\'ve selected don\'t benefit from our Early Booker Package offer. Arrivals before ' + moment(arrivalWindowStart).format('Do MMMM YYYY') + ' are excluded, so please adjust and search again.')
        return false;
    }
    // Is the arrival journey after the last offer date? Or is the journey window is excluded from the offer
    if (outbound >= departureWindowEnd.getTime() && offerType != 'free-nights') {
        warning('Your dates aren\'t eligible for our Early Booker Package offer.', 'Unfortunately, the dates you\'ve selected don\'t benefit from our Early Booker Package offer. Arrivals after ' + moment(departureWindowEnd).subtract(1, 'days').format('Do MMMM YYYY') + ' are excluded, so please adjust and search again.')
        return false;
    }*/
    if (offerType !== 'free-nights' && typeof exclusionWindows === 'object' && exclusionWindows.length && dateIsExcluded(outbound)) {
        warningOfferModal();
        return false;
    }
    //Free Nights
    if (outbound.getTime() < arrivalWindowStart.getTime()
        || outbound.getTime() > arrivalWindowEnd.getTime()
        || (inbound > departureWindowEnd.getTime()
            || (exclusionWindowStart.getTime() <= outbound.getTime() && outbound.getTime() <= exclusionWindowEnd.getTime())
            || (outbound.getTime() < exclusionWindowStart.getTime() && inbound >= exclusionWindowStart.getTime()))
    ) {
        var exclusionStr;
        if (offerType == 'free-nights') {
            if (noExclusion) {
                exclusionStr = moment(arrivalWindowStart).format('Do MMMM YYYY') + ' and arrivals after ' + moment(arrivalWindowEnd).format('Do MMMM YYYY');
            }
            warning('Sorry, your dates aren\'t eligible for this offer.', 'The dates you\'ve selected don\'t benefit from our Free Nights and Free Days package. Stays before ' + exclusionStr + ' are excluded, so please adjust your dates and search again.'); GA.sendEvent('Alert', 'Search', 'Free Nights Offer Off Sale');
        }
        else {
            warningOfferModal();
            // if (noExclusion) {
            //     exclusionStr = 'Arrivals after  ' + moment(arrivalWindowEnd).format('Do MMMM YYYY');
            // }
            // else {
            //     exclusionStr = 'Easter (' + moment(exclusionWindowStart).format('Do MMMM') + ' – ' + moment(exclusionWindowEnd).format('Do MMMM') + ') and arrivals after  ' + moment(arrivalWindowEnd).format('Do MMMM');
            // }
            // warning('Sorry, your travel dates are not eligible for an offer.', 'Unfortunately, the dates you\'ve selected do not benefit from our free dining or 10% discount offers for ' + arrivalWindowEnd.getFullYear() + ' arrivals. ' + exclusionStr + ' are excluded, please adjust your dates and search again.'); GA.sendEvent('Alert', 'Search', 'Free Dine Offer Off Sale');
        }
        return false;
    }
    // Otherwise valid
    return true;
}

// Check duration is valid for holiday type
function duration(type, nights) {
    // Determine minimum duration
    var minduration = 1;
    switch (type) { case 'flights': case 'package': minduration = 5; }
    // Is duration too short?
    if (nights < minduration) { warning('Sorry, your hotel stay is too short.', 'Unfortunately we require package hotel stays to be five nights or more. Please adjust your dates or call our Disney Experts freephone on ' + phone + '* for help with your enquiry.'); GA.sendEvent('Alert', 'Search', 'Below Minimum Stay Duration'); return false; };
    // Otherwise valid
    return true;
}

// Get dates for validation
function valid(type) {
    // Get outbound and inbound dates and convert to date object
    var monthyear = $('#month').val();
    var year = parseInt(monthyear.split('^')[1]);
    var month = parseInt(monthyear.split('^')[0]) - 1; // Months are zero-indexed in JavaScript
    var day = parseInt($('#day').val());
    var nights = parseInt($('#nights').val())
    var outbound = new Date(year, month, day);
    var inbound = new Date(new Date(+outbound + (nights * 864e5)).toDateString()); // Convert to string to ensure 00:00:00
    var minimum = new Date(+new Date + 6048e5); // Bookings must be a minimum of 7 days (604800000 milliseconds) from today for flights/packages
    var minimumdays = 7;
    // Override miminum for ticket only
    switch (type) {
        case 'tickets': minimum = new Date(+new Date + 2592e5); minimumdays = 3; submit = true; break; // Bookings must be a minimum of 3 days (259200000 milliseconds) from today for ticket only
    };
    // Override minimum for travel agents
    if (agent) { minimum = new Date(+new Date + 1469e6); minimumdays = 17; } // Bookings must be a minimum of 17 days (1469000000 milliseconds) from today for all product types
    // Check eligible departure
    if (minimum.getTime() > outbound.getTime()) { warning('Sorry, your departure date is within ' + minimumdays + ' days.', 'Please adjust your dates and search again, or call our Disney Experts freephone on ' + phone + '* for help with your enquiry.'); GA.sendEvent('Alert', 'Search', 'Departure Within ' + minimumdays + ' Days'); return false; }
    // Validate pararmeters for hotel-inclusive holidays; ticket only searches are excluded from these checks
    if (type != 'tickets') {
        // Maximum of 8 pax online, excluding infants
        var infants = $('.ages select:has(option[value=1]:selected)').length;
        var passengers = parseInt($('#adults').val()) + parseInt($('#children').val()) - infants;
        if (passengers > 8) { warning('Sorry, your party includes more than eight guests.', 'Unfortunately to accommodate your whole party you\'ll need to book multiple rooms. You can do this by making separate reservations for each room, or call our Disney Experts freephone on ' + phone + '* for help with your enquiry.'); GA.sendEvent('Alert', 'Search', 'Occupancy: 9+ Guests'); return false; }
        // Maximum of 1 infant online
        if (infants > 1) { warning('Sorry, your party includes more than 1 infant.', 'Unfortunately we\'re unable to book your holiday online. Please call our Disney Experts freephone on ' + phone + '* for help with your enquiry.'); GA.sendEvent('Alert', 'Search', 'Occupancy: 2+ Infants'); return false; }
        // Check if arrival date is eligble for the current offer
        journey = offerEligible(outbound, inbound);
        // If hotel is available then check duration requirements for selected option
        if (journey) { journey = duration(type, nights); }
        // Is journey okay?
        if (!journey) { return false; };
        // If valid journey then show intermission
        intermission();
        // Pause carousel
        // billboard.slick('slickPause');
    }
    // Post form
    return true;
}

// Set button content
function button(type) {
    var label
    switch (type) {
        case 'flights': label = 'Search Packages'; break;
        case 'package': case 'hotel': label = 'Search Packages'; break;
        case 'tickets': label = 'Buy Tickets'; break;
    }
    $('#quick-quote input.button').val(label);
}

// Return hotel category based on code
function category(code) {
    switch (code) {
        case 'ALL': return 'All Hotels'; break;
        case 'VAL': return 'Value Hotels'; break;
        case 'MOD': return 'Moderate Hotels'; break;
        case 'DLX': return 'Deluxe Hotels'; break;
        case 'HAH': return 'Deluxe Villas'; break;
        case 'SWD': return 'Other Hotels'; break;
        case 'GNB': return 'Local Hotels'; break;
        case 'RHV': return 'Orlando Villas'; break;
        case 'VER': return 'Beach Hotels'; break;
    }
}

// Show/hide accessible rooms checkbox
function accessible(type) {
    // Get checkbox
    var accessible = $('.accessible');
    // Suppress for tickets option
    if (type == 'tickets') { accessible.addClass('hide'); } else { accessible.removeClass('hide'); }
}

// -------------------------------------------------------------------------------------------------------------------

// On ready
$(document).ready(function () {

    // Catch form post
    $('#form').submit(function (event) {
        // Prevent DOM from unloading before we have fired the tracking code
        event.preventDefault();
        // Get transport method
        var type = $('input:radio:checked').val().toLowerCase();
        // Valid dates?
        if (valid(type)) {
            doSearch()
        }
    });

});

function doSearch() {
    // Get transport method
    var type = $('input:radio:checked').val().toLowerCase();

    form = $('#form').get(0);
    var query;
    var pageview;
    var analytics;
    // Set delay; default equals 2 seconds after image has loaded
    delay = 2000;
    // Submission varies by type
    switch (type) {
        case 'flights': query = 'Flights + Hotel + Ticket'; pageview = 'flights'; analytics = 'QQUKContFlight_Link'; delay = 500; break; // Override for flight inclusive
        case 'package': query = 'Hotel + Ticket'; pageview = 'hotel-ticket'; analytics = 'QQUKContPackage_Link'; break;
        case 'hotel': query = 'Hotel Only'; pageview = 'hotel'; analytics = 'QQUKContRoomOnly_Link'; break;
        case 'tickets': query = 'Ticket Only'; pageview = 'tickets'; analytics = 'QQUKContTicketOnly_Link'; delay = 250; break;
    }
    // Capture search data and log in Google Analytics
    searches(type, query, brand, null)
    // Add _trackEvent to the _gaq
    GA.sendEvent('Book', 'Search (Packages)', query);
    // Add virtual pageview to enable funnel reporting
    _gaq.push(['_trackPageview', '/walt-disney-world/book/search/' + pageview + '/']);
    // Add link tracking to Adobe Analytics call
    // s_wdpro.trackClick({},analytics);
    // Add _linkByPost to the _gaq to track cross-domain searches; appends querystring variables to form action
    GA.linkByPost(form);
    // Add the call to the _gaq queue; delay form submission to allow _trackEvent to complete and interstitial to load
    submitForm();
}